import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;
var __jsx = React.createElement;
import { useState } from 'react';
import React from 'react';
import { Image, Pressable, View } from 'react-native';
import Icon from 'react-native-vector-icons/Feather';
import { useRouter } from 'solito/router';
import { formatPhoneNumber } from '~/api/models/ModelFunctions';
import { Config } from '~/common/Config';
import { VisualMode } from '~/common/Env';
import { LocalImageEnum, localImageSource, runOnPlatform } from '~/common/Helpers';
import Localize from '~/common/Localize';
import { AnalyticsEvents } from '~/common/analytics/Analytics';
import useAnalytics from '~/common/hooks/analytics/useAnalytics';
import useVisualMode from '~/common/hooks/useVisualMode';
import { Alert } from '~/components/Alert';
import ListItem from '~/components/ListItem';
import MainButton, { MainButtonType } from '~/components/MainButton';
import { onLinkPress } from '~/components/OpenLink';
import WebModal from '~/components/WebModal';
import { RouteName, getRouteUrl } from '~/navigation/Routes';
import { Body3, Container, SubTitle2, Theme, ThemeName, themed, useAppTheme } from '~/styles/Theme';
import { buildAddress, buildMapAddress, buildOpeningTimes } from './HomeViewModel';
import OpeningHoursItem from './OpeningHoursItem';
import AnnouncementText from './components/AnnouncementText';
import NotificationBannerItem from './components/NotificationBannerItem';
export var GalleryBanner = function GalleryBanner() {
  return __jsx(Pressable, {
    style: {
      paddingTop: 25
    }
  }, __jsx(Image, {
    style: {
      width: 'auto',
      height: 200,
      resizeMode: 'contain'
    },
    source: require('../../../resources/img/DopDopCard.png')
  }));
};
export var showNotificationBanner = function showNotificationBanner(branch, visualMode) {
  var onlineBookingAnnouncementEnabled = branch.onlineBookingAnnouncementEnabled,
    onlineBookingAnnouncementContent = branch.onlineBookingAnnouncementContent;
  return visualMode !== VisualMode.Screenshot && onlineBookingAnnouncementEnabled && onlineBookingAnnouncementContent != null && onlineBookingAnnouncementContent !== '';
};
export var NotificationBanner = function NotificationBanner(props) {
  var visualMode = useVisualMode();
  var onlineBookingAnnouncementContent = props.branch.onlineBookingAnnouncementContent;
  var _useState = useState(false),
    isModalVisible = _useState[0],
    setModalVisible = _useState[1];
  var _useRouter = useRouter(),
    push = _useRouter.push;
  if (showNotificationBanner(props.branch, visualMode)) {
    var cleanContent = onlineBookingAnnouncementContent.replace(/<\/?[^>]+(>|$)/g, '');
    return __jsx(React.Fragment, null, __jsx(NotificationBannerItem, {
      title: Localize('home.ImportantMessage'),
      subtitle: cleanContent,
      onPress: function onPress() {
        return runOnPlatform({
          web: function web() {
            return setModalVisible(true);
          },
          "native": function native() {
            return push(getRouteUrl(RouteName.NotificationBanner));
          }
        });
      }
    }), __jsx(AnnouncementModal, {
      content: onlineBookingAnnouncementContent,
      isModalVisible: isModalVisible,
      onClose: function onClose() {
        return setModalVisible(false);
      }
    }));
  }
  return null;
};
var AnnouncementModal = function AnnouncementModal(props) {
  var isModalVisible = props.isModalVisible,
    onClose = props.onClose,
    content = props.content;
  return __jsx(WebModal, {
    isVisible: isModalVisible,
    hideCloseButton: false,
    onClose: onClose
  }, __jsx(View, {
    style: {
      width: '100%',
      padding: 24
    }
  }, __jsx(HorizontalContainer, null, __jsx(ImageContainer, null, __jsx(Image, {
    style: {
      width: 40,
      height: 40,
      resizeMode: 'contain'
    },
    source: localImageSource(LocalImageEnum.loudspeaker)
  })), __jsx(SubTitle2, {
    adjustsFontSizeToFit: true,
    style: {
      marginBottom: 10
    }
  }, Localize('home.ImportantMessage'))), __jsx(AnnouncementText, {
    content: content
  })));
};
export var Voucher = function Voucher(props) {
  var branch = props.branch,
    country = props.country,
    env = props.env;
  var analytics = useAnalytics();
  var url = "".concat(Config.voucherURL(env), "/").concat(branch.domainName);
  var label = country.toUpperCase() === 'US' || country.toUpperCase() === 'AU' ? 'giftcard.title' : 'voucher.title';
  return __jsx(MainButton, {
    type: MainButtonType.main,
    onPress: function onPress() {
      analytics.logAnalyticsEvent(AnalyticsEvents.LinkOutToVouchers);
      onLinkPress(url);
    },
    accessoryIcon: 'external-link',
    testID: 'voucherButton'
  }, Localize(label));
};
export var Shop = function Shop(_ref) {
  var url = _ref.url;
  var analytics = useAnalytics();
  return __jsx(MainButton, {
    type: MainButtonType.main,
    onPress: function onPress() {
      analytics.logAnalyticsEvent(AnalyticsEvents.HomeShop);
      onLinkPress(url);
    },
    accessoryIcon: 'external-link',
    testID: 'shopButton'
  }, Localize('home.shop'));
};
export var PhoneNumber = function PhoneNumber(props) {
  var theme = useAppTheme();
  var analytics = useAnalytics();
  var phone = props.phone,
    country = props.country,
    banner = props.banner,
    homePage = props.homePage;
  var _formatPhoneNumber = formatPhoneNumber(phone, country),
    formattedPhone = _formatPhoneNumber.formattedPhone,
    phoneURI = _formatPhoneNumber.phoneURI;
  return __jsx(ListItem, {
    testID: 'branchPhoneNumber',
    subHeader: Localize('home.callUs'),
    title: formattedPhone,
    onPress: function onPress() {
      analytics.logAnalyticsEvent(AnalyticsEvents.HomePhone);
      onLinkPress(phoneURI);
    },
    image: __jsx(Icon, {
      name: "phone",
      color: theme.colors.homeScreen.icon,
      size: 25
    }),
    banner: banner !== undefined ? banner : true,
    homePage: homePage !== undefined ? homePage : false
  });
};
export var SalonAddress = function SalonAddress(props) {
  var theme = useAppTheme();
  var analytics = useAnalytics();
  var address = props.branch.address;
  return address ? __jsx(ListItem, {
    testID: 'branchSalonAddress',
    subHeader: Localize('home.findUs'),
    title: buildAddress(address),
    onPress: function onPress() {
      analytics.logAnalyticsEvent(AnalyticsEvents.HomeMap);
      var url = buildMapAddress(props.branch);
      url ? onLinkPress(url) : Alert.alert(Localize('home.locationError'), '', [{
        text: Localize('global.ok')
      }]);
    },
    image: __jsx(Icon, {
      name: "map-pin",
      color: theme.colors.homeScreen.icon,
      size: 25
    }),
    banner: props.banner !== undefined ? props.banner : true,
    homePage: props.homePage !== undefined ? props.homePage : false
  }) : null;
};
export var EmailAddress = function EmailAddress(props) {
  var theme = useAppTheme();
  var analytics = useAnalytics();
  var email = props.email,
    banner = props.banner,
    homePage = props.homePage;
  return __jsx(ListItem, {
    testID: 'branchEmailAddress',
    subHeader: Localize('home.emailUs'),
    title: email,
    oneLineTitle: true,
    onPress: function onPress() {
      analytics.logAnalyticsEvent(AnalyticsEvents.HomeEmail);
      onLinkPress('mailto:' + email);
    },
    image: __jsx(Icon, {
      name: "mail",
      color: theme.colors.homeScreen.icon,
      size: 25
    }),
    banner: banner !== undefined ? banner : true,
    homePage: homePage !== undefined ? homePage : false
  });
};
export var OpeningTimes = function OpeningTimes(props) {
  var branch = props.branch;
  if (branch.defaultWorkWeek == null) {
    return null;
  }
  var items = buildOpeningTimes(branch.defaultWorkWeek).map(function (day) {
    return day.map(function (item) {
      return __jsx(OpeningHoursItem, {
        key: item.date,
        day: item.date,
        startTime: item.startTime,
        endTime: item.endTime
      });
    });
  });
  return __jsx(OpeningTimesContainer, {
    testID: 'branchOpeningTimes'
  }, __jsx(OpeningTimesText, {
    style: {
      paddingBottom: 20
    }
  }, Localize('home.openings')), items);
};
export var MainActionButton = function MainActionButton(props) {
  return props.branch.waitListEnabled ? __jsx(WaitListButton, {
    waitListUrl: props.branch.waitListUrl
  }) : __jsx(BookNowButton, null);
};
var BookNowButton = function BookNowButton() {
  var _useRouter2 = useRouter(),
    push = _useRouter2.push;
  var analytics = useAnalytics();
  return __jsx(MainButton, {
    type: MainButtonType.main,
    accessoryIcon: "chevron-right",
    testID: "viewServicesButton",
    onPress: function onPress() {
      analytics.logAnalyticsEvent(AnalyticsEvents.HomeBookNow);
      push(getRouteUrl(RouteName.ServiceSelection, {
        showSpecialOffers: false
      }));
    }
  }, Localize('home.book'));
};
var WaitListButton = function WaitListButton(props) {
  var analytics = useAnalytics();
  return __jsx(MainButton, {
    type: MainButtonType.main,
    onPress: function onPress() {
      analytics.logAnalyticsEvent(AnalyticsEvents.LinkOutToWaitList);
      onLinkPress(props.waitListUrl);
    },
    accessoryIcon: 'external-link',
    testID: "waitListButton"
  }, Localize('home.waitingList'));
};
export var BuyCoursesButton = function BuyCoursesButton() {
  var _useRouter3 = useRouter(),
    push = _useRouter3.push;
  var analytics = useAnalytics();
  return __jsx(MainButton, {
    type: MainButtonType.main,
    accessoryIcon: "chevron-right",
    testID: "buyCoursesButton",
    onPress: function onPress() {
      analytics.logAnalyticsEvent(AnalyticsEvents.HomeBuyCourse);
      push(getRouteUrl(RouteName.PurchaseCourse));
    }
  }, Localize('home.buyCourse'));
};
export var GrownByPhorest = function GrownByPhorest() {
  return __jsx(HomeGrownWrapper, null, __jsx(HomeGrownText, null, Localize('home.grown')), __jsx(PhorestText, null, "Phorest"));
};
var OpeningTimesText = themed(SubTitle2)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  padding-bottom: 20px;\n  ", "\n"])), function (props) {
  return "color: ".concat(Theme(props).colors.homeScreen.subText);
});
var OpeningTimesContainer = themed(Container)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  justify-content: center;\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  min-height: 70px;\n  border-width: 1px;\n  border-color: transparent;\n"])), function (props) {
  return "backgroundColor: ".concat(Theme(props).name === ThemeName.NeonNights ? '#1F1565' : 'white');
}, function (props) {
  return "borderRadius: ".concat(Theme(props).name === ThemeName.NeonNights ? '8px' : '0');
}, function (props) {
  return "margin: ".concat(Theme(props).name === ThemeName.NeonNights ? '6px 0px' : '0');
}, function (props) {
  return "padding: ".concat(Theme(props).name === ThemeName.NeonNights ? '20px 20px 10px 20px' : '20px 0 0 0');
}, function (props) {
  return "backgroundColor: ".concat(Theme(props).name === ThemeName.NeonNights ? '#1F1565' : 'white');
});
var HomeGrownWrapper = themed(Container)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  flex: 1\n  flex-direction: row\n  justify-content: center\n  align-items: center\n  padding-top: 30px\n  padding-bottom: 20px\n"])));
var HomeGrownText = themed(Body3)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  ", "\n"])), function (props) {
  return "color: ".concat(Theme(props).colors.homeScreen.subText);
});
var PhorestText = themed(Body3)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  ", "\n"])), function (props) {
  return "color: ".concat(Theme(props).colors.main);
});
var ImageContainer = themed(Container)(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  padding-right: 10px\n  padding-bottom: 10px\n"])));
var HorizontalContainer = themed(Container)(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  flex-direction: row\n  align-items: center\n  justify-content: flex-start\n  margin-right: 20px\n"])));